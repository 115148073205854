import React from 'react';
import { Outlet } from 'react-router-dom';
import LandingNavbar from './LandingNavbar';

const LandingLayout = () => {
  return (
    <div className="bg-bgColor min-h-screen">
      <LandingNavbar />
      <Outlet />
    </div>
  );
};

export default LandingLayout;
