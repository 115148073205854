import React from 'react';

import L1 from 'assets/images/landing/L1.png';
import L2 from 'assets/images/landing/L2.png';
import L3 from 'assets/images/landing/L3.png';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const navigate = useNavigate();
  const titles = [
    'A 3D render of an astronaut walking in a green desert',
    'A synthwave style sunset above the reflecting water of the sea, digital art',
    'A Formula 1 car driving on a neon road',
  ];

  const [selectedImage, setSelectedImage] = React.useState({
    image: L2,
    title: titles[2],
  });

  const images = [
    'https://images.unsplash.com/photo-1668789129719-343c1db060b9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
    'https://images.unsplash.com/photo-1668767483967-cc2325edafbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    'https://images.unsplash.com/photo-1667835949430-a2516cc93d27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
    'https://images.unsplash.com/photo-1668696381185-46c77ee52d1a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80',
    'https://images.unsplash.com/photo-1661961110671-77b71b929d52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  ];

  return (
    <div className="">
      <div
        id="main"
        style={{
          backgroundImage: `url(${selectedImage.image})`,
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
      >
        <div className="text-white absolute top-1/2 left-1/2 -translate-y-10 ">
          <div className="text-5xl font-bold -translate-x-1/2">
            Text to AI Art
          </div>
          <div
            onClick={() => navigate('/login')}
            className="cursor-pointer bg-gradient-to-tl from-pink-500 via-pink-300 to-blue-500 bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all -translate-x-1/2 text-center px-8 py-3 text-lg rounded-lg shadow mt-5 w-fit"
          >
            Start Creating
          </div>
        </div>
        {/*         <div>
          <div className="flex flex-col justify-center items-center h-screen gap-3 pb-8 ">
            Text to Art is a web app that uses machine learning to convert text
          </div>
        </div> */}
        <div className="flex flex-col justify-end items-center h-screen gap-3 pb-8 ">
          <div className="bg-gray-800/80 px-4 py-2 text-gray-400 text-center font-medium text-base rounded-full">
            {selectedImage.title}
          </div>

          <div className="flex gap-3">
            {' '}
            <img
              src={L1}
              alt={titles[0]}
              style={{
                width: '120px',
              }}
              className={`rounded-lg ${
                selectedImage.image === L1 ? 'border-2 border-white' : ''
              }`}
              onClick={() =>
                setSelectedImage({
                  image: L1,
                  title: titles[0],
                })
              }
            />
            <img
              src={L2}
              alt={titles[1]}
              style={{
                width: '120px',
              }}
              className={`rounded-lg ${
                selectedImage.image === L2 ? 'border-2 border-white' : ''
              }`}
              onClick={() =>
                setSelectedImage({
                  image: L2,
                  title: titles[1],
                })
              }
            />
            <img
              src={L3}
              alt={titles[2]}
              style={{
                width: '120px',
              }}
              className={`rounded-lg ${
                selectedImage.image === L3 ? 'border-2 border-white' : ''
              }`}
              onClick={() =>
                setSelectedImage({
                  image: L3,
                  title: titles[2],
                })
              }
            />
          </div>
        </div>
      </div>

      <div
        className="shadow-2xl flex justify-center flex-wrap gap-8 py-5"
        id="discover"
      >
        {images.map((image, index) => (
          <img
            className="md:min-h-fit max-w-xl rounded-lg border border-gray-600 w-full"
            src={image}
            alt=""
          />
        ))}
      </div>

      {/*       <img
        src={L2}
        alt={texts[0]}
        referrerPolicy="no-referrer"
        style={{
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
        }}
      /> */}
      <footer className="bg-bgColor">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2"> </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-base text-gray-400">
              &copy; 2022 Your VirtArt, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Homepage;
