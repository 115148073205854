import { auth } from 'firebaseConfig';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Loading from 'components/UI/Loading';

const ProtectedRoute = () => {
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (userA) => {
      console.log('userA', userA);
      if (userA) {
        setUser(userA); // ...
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen bg-bgColor">
          <Loading />
        </div>
      ) : user ? (
        navigate('/home', { replace: true })
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default ProtectedRoute;
