import { UserContext } from 'context/UserContext';
import React, { useContext } from 'react';

const ProfilePage = () => {
  const { email, name, photoURL } = useContext(UserContext);

  return (
    <div className="flex justify-center mt-5">
      <div className="w-[660px] ">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="text-white text-xl mb-3">Profile</div>

          <form action="#" method="POST">
            <div className="shadow sm:rounded-md">
              <div className="space-y-6 bg-bgColor px-4 py-5 sm:p-6 border border-gray-600  rounded-lg shadow-sm ">
                <div>
                  <label className="block text-sm font-medium text-gray-200">
                    Photo
                  </label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      <img
                        className="h-full w-full text-gray-300"
                        src={photoURL}
                        alt="Profile"
                        referrerPolicy="no-referrer"
                      />
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-200"
                    >
                      Email
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        defaultValue={email}
                        id="email"
                        name="email"
                        type="email"
                        required
                        className="bg-transparent text-gray-300 block w-full appearance-none rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-200"
                    >
                      Fullname
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        id="fullname"
                        defaultValue={name}
                        name="fullname"
                        type="email"
                        required
                        className="bg-transparent text-gray-300 block w-full appearance-none rounded-md border border-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
