import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import ProtectedRoute from 'components/routes/ProtectedRoute';

import DashboardPage from 'pages/dashboard/DashboardPage';
import Homepage from 'pages/landing/Homepage';

import LoginPage from 'pages/auth/LoginPage';
import RegisterPage from 'pages/auth/RegisterPage';

import { UserProvider } from 'context/UserContext';
import Layout from 'components/layout';
import LandingLayout from 'components/landingLayout';
import ProfilePage from 'pages/dashboard/ProfilePage';
import MyCraftPage from 'pages/dashboard/MyCraftPage';
import AllWorksPage from 'pages/dashboard/AllWorksPage';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route path="home" element={<DashboardPage />} />
            <Route path="my-crafts" element={<MyCraftPage />} />
            <Route path="all-works" element={<AllWorksPage />} />
            <Route path="profile" element={<ProfilePage />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route element={<LandingLayout />}>
            <Route index element={<Homepage />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Route>
    )
  );

  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
