import { UserContext } from 'context/UserContext';
import React, { useContext } from 'react';
import Navbar from 'components/layout/Navbar';
import { PlusIcon } from '@heroicons/react/24/outline';

import PicassoPng from 'assets/images/picasso.jpg';
import VanGogh from 'assets/images/vanGogh.jpg';
import Sketch from 'assets/images/sketch.jpg';
import Graffiti from 'assets/images/graffiti.jpg';
import Dark from 'assets/images/dark.jpg';
import Epic from 'assets/images/epic.jpg';
import Unreal from 'assets/images/unreal.jpg';
import CyberPunk from 'assets/images/cyberpunk.jpg';

import { Configuration, OpenAIApi } from 'openai';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  setDoc,
} from 'firebase/firestore';
import { db, storage } from 'firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const images = [
  { src: PicassoPng, alt: 'Picasso' },
  {
    src: VanGogh,
    alt: 'Van Gogh',
  },
  {
    src: Sketch,
    alt: 'Sketch',
  },
  { src: Graffiti, alt: 'Graffiti' },
  { src: Dark, alt: 'Dark' },
  { src: Epic, alt: 'Epic' },
  { src: Unreal, alt: 'Unreal' },
  { src: CyberPunk, alt: 'CyberPunk' },
];

function getBase64Image(img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL('image/png');
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

const DashboardPage = () => {
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedArt, setSelectedArt] = React.useState(null);
  const [prompText, setPromptText] = React.useState('');
  const [selectN, setSelectN] = React.useState(1);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const badges = [
    'Sunset, digital art',
    'Panda, oil painting',
    'Landscape, digital art',
    'Bird, by Johannes Vermeer',
  ];

  const handleGenerate = async () => {
    try {
      setUploadedImage([]);
      const configuration = new Configuration({
        apiKey: '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',
      });
      const openai = new OpenAIApi(configuration);

      if (prompText.length === 0) {
        // eslint-disable-next-line no-alert
        alert('Please enter a prompt text');
        return;
      }
      setOpen(true);
      setLoading(true);
      const response = await openai.createImage({
        prompt: selectedArt ? `${prompText},${selectedArt}` : prompText,
        n: selectN,
        size: '512x512',
      });

      response.data.data.forEach((image) => {
        setUploadedImage((prev) => [
          ...prev,
          { url: image.url, publish: false },
        ]);
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // url to base64
  const getBase64FromImageUrl = async (u) => {
    // cors proxy
    // x-requested-with
    const proxyUrl = 'https://anywhere-alternative-fors.onrender.com/';
    const res = await fetch(proxyUrl + u, {
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
    });
    const blob = await res.blob();
    // convert to file object

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(blob);
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);
      const newImages = [];

      uploadedImage.forEach(async (image, index) => {
        await getBase64FromImageUrl(image.url)
          .then((data) => {
            newImages.push(data);
          })
          .then(async () => {
            newImages.forEach((img) => {
              const file = dataURLtoFile(img, uuidv4());

              const storageRef = ref(storage, `images/${file.name}`);
              const uploadTask = uploadBytesResumable(storageRef, file, {
                contentType: 'image/png',
              });

              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log(`Upload is ${progress}% done`);
                  // eslint-disable-next-line default-case
                  switch (snapshot.state) {
                    case 'paused':
                      console.log('Upload is paused');
                      break;
                    case 'running':
                      console.log('Upload is running');
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  // eslint-disable-next-line default-case
                  switch (error.code) {
                    case 'storage/unauthorized':
                      // User doesn't have permission to access the object
                      break;
                    case 'storage/canceled':
                      // User canceled the upload
                      break;

                    case 'storage/unknown':
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                      /*  const docRef = doc(db, 'users', userCtx.id);

                      await setDoc(
                        docRef,
                        {
                          images: arrayUnion({
                            url: downloadURL,
                            publish: false,
                          }),
                        },
                        { merge: true }
                      ); */
                      const docRef = await addDoc(
                        collection(db, 'users', userCtx.id, 'images'),
                        {
                          url: downloadURL,
                          published: false,
                          title: prompText,
                        }
                      );
                      console.log('Document written with ID: ', docRef.id);
                      setSaveLoading(false);
                      setOpen(false);
                    }
                  );
                }
              );
            });
          });
      });

      /*   */
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error saving image');
    }
  };

  return (
    <div className=" text-white mx-auto w-full md:w-[680px] space-y-2 px-3 md:p-0">
      <div className="font-medium text-xl">Enter prompt</div>
      <div>
        <input
          defaultValue={prompText}
          onChange={(e) => setPromptText(e.target.value)}
          type="text"
          placeholder='Enter any prompt here, e.g. "A cat astronaut..."'
          className="bg-transparent border-2 border-pink-400 rounded-md w-full px-4 py-2.5 text-gray-100"
        />
      </div>
      <div className="flex gap-2 overflow-auto py-2">
        {badges.map((badge) => (
          <div
            className="bg-gray-800 py-2 px-3 rounded-full text-sm text-gray-300 min-w-fit cursor-pointer "
            onClick={() => setPromptText(badge)}
          >
            {badge}
            <PlusIcon className="h-4 w-4 inline ml-2" />
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 py-3">
        <div className="text-xl font-medium ">Choose an art style</div>
        <div className="text-xs text-gray-400">(Optional)</div>
      </div>
      <div>
        <div className="flex items-center gap-5 flex-wrap">
          {images.map((image) => (
            <div
              className="cursor-pointer "
              onClick={() => setSelectedArt(image.alt)}
            >
              <img
                src={image.src}
                alt={image.alt}
                className={`border h-24 w-24 rounded-xl transition-all ${
                  selectedArt === image.alt
                    ? ' border-pink-200'
                    : ' border-transparent'
                } `}
              />
              <div
                className={`border-b border-r border-l transition-all text-xs text-gray-200 -translate-y-4 backdrop-blur p-1 flex justify-center rounded-b-xl overflow-hidden ${
                  selectedArt === image.alt
                    ? ' border-pink-400'
                    : ' border-transparent'
                }`}
              >
                {image.alt}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pb-20  ">
        <div className="border border-gray-400 w-full flex justify-between items-center p-3 rounded-xl">
          <div className="text-sm">Image Number</div>
          <div className="flex gap-2">
            <div
              onClick={() => setSelectN(1)}
              className={`cursor-pointer px-3 py-1 bg-gray-700 rounded-lg ${
                selectN === 1 ? 'bg-indigo-400' : ''
              }`}
            >
              1
            </div>
            <button
              disabled
              onClick={() => setSelectN(2)}
              className={`relative px-3 py-1 bg-gray-700 rounded-lg cursor-not-allowed  ${
                selectN === 2 ? 'bg-indigo-400' : ''
              }`}
            >
              <span
                className="absolute
              -top-2 right-0 w-fit h-fit px-1 bg-pink-700 rounded-lg opacity-70 text-xs"
              >
                Pro
              </span>
              2
            </button>
            <button
              disabled
              onClick={() => setSelectN(4)}
              className={`relative px-3 py-1 bg-gray-700 rounded-lg cursor-not-allowed ${
                selectN === 4 ? 'bg-indigo-400' : ''
              }`}
            >
              <span
                className="absolute
              -top-2 right-0 w-fit h-fit px-1 bg-pink-700 rounded-lg opacity-70 text-xs"
              >
                Pro
              </span>
              4
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={() => handleGenerate()}
        className="fixed bottom-8 left-1/2 -translate-x-1/2 w-5/6 md:w-[680px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 py-2.5 px-4 rounded-md li font-medium tracking-wider"
      >
        Generate
      </button>
      <Modal open={open} setOpen={setOpen}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col gap-8">
              {uploadedImage &&
                uploadedImage.map((image, index) => (
                  <div className="flex flex-col justify-center">
                    <div className="text-lg font-semibold">{index + 1}.</div>
                    <img src={image.url} alt="" className="w-full h-full" />
                  </div>
                ))}
            </div>

            <Button
              isLoading={saveLoading}
              onClick={handleSave}
              className="w-full mt-4
        bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 py-2.5 px-4 rounded-md li font-medium tracking-wider"
            >
              Save
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default DashboardPage;
