import { UserContext } from 'context/UserContext';
import { db } from 'firebaseConfig';
import React, { useContext, useEffect, useState } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { ShareIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import Loading from 'components/UI/Loading';

const AllWorksPage = () => {
  const userCtx = useContext(UserContext);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getImages = async () => {
      setIsLoading(true);
      const docRef = query(
        collection(db, 'users', userCtx.id, 'images'),
        where('published', '==', true)
      );
      const docSnap = await getDocs(docRef);
      const img = [];
      docSnap.forEach((d) => {
        img.push({ ...d.data(), id: d.id });
      });
      setImages(img);
      setIsLoading(false);
    };

    getImages();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-5 w-full ">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap gap-8  w-full">
          {' '}
          {images.length > 0 &&
            images.map((image) => (
              // eslint-disable-next-line jsx-a11y/alt-text
              <div className="flex flex-col items-center w-full sm:w-fit justify-center">
                <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
                  <a href={image.url} data-sub-html={image.title}>
                    <img
                      alt=""
                      src={image.url}
                      data-src={image.url}
                      className="w-60 inline-block mr-3 rounded-2xl"
                      data-sub-html={
                        image.publish ? 'Published' : 'Not Published'
                      }
                    />
                  </a>
                </LightGallery>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AllWorksPage;
