import { createContext, useState } from 'react';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: '',
    name: '',
    id: '',
    photoURL: '',
  });

  const value = {
    name: user.name,
    email: user.email,
    id: user.id,
    photoURL: user.photoURL,
    setUserInfo: (name, email, uid, photoURL) =>
      setUser({ name, email, id: uid, photoURL }),
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
