import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { UserContext } from 'context/UserContext';
import { auth } from 'firebaseConfig';
import Loading from 'components/UI/Loading';

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);

  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const { email, displayName, uid, photoURL } = user;
        userCtx.setUserInfo(displayName, email, uid, photoURL);
        // ...
      } else {
        // User is signed out
        navigate('/login', {
          replace: true,
        });
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen bg-bgColor">
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default PrivateRoute;
