import { UserContext } from 'context/UserContext';
import { db } from 'firebaseConfig';
import React, { useContext, useEffect, useState } from 'react';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';

import Loading from 'components/UI/Loading';

const MyCraftPage = () => {
  const userCtx = useContext(UserContext);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getImages = async () => {
      setIsLoading(true);
      const docRef = collection(db, 'users', userCtx.id, 'images');
      const docSnap = await getDocs(docRef);
      const img = [];
      docSnap.forEach((d) => {
        img.push({ ...d.data(), id: d.id });
      });
      setImages(img);
      setIsLoading(false);
    };

    getImages();
  }, []);

  const handleDelete = async (id) => {
    const docRef = doc(db, 'users', userCtx.id, 'images', id);
    await deleteDoc(docRef);
    setImages(images.filter((i) => i.id !== id));
  };

  const handlePublish = async (id) => {
    const docRef = doc(db, 'users', userCtx.id, 'images', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        published: true,
      });
    }

    // find images in images array and update published to true
    const updatedImages = images.map((i) => {
      if (i.id === id) {
        return { ...i, published: true };
      }
      return i;
    });
    setImages(updatedImages);
  };

  const handleUnpublish = async (id) => {
    const docRef = doc(db, 'users', userCtx.id, 'images', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        published: false,
      });
    }

    const updatedImages = images.map((i) => {
      if (i.id === id) {
        return { ...i, published: false };
      }
      return i;
    });
    setImages(updatedImages);
  };

  return (
    <div className="max-w-7xl mx-auto mt-5">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap gap-8">
          {' '}
          {images.length > 0 &&
            images.map((image) => (
              // eslint-disable-next-line jsx-a11y/alt-text
              <div className="flex flex-col items-center w-full sm:w-fit justify-center">
                <LightGallery
                  addClass="w-fit"
                  plugins={[lgZoom, lgVideo]}
                  mode="lg-fade"
                >
                  <a href={image.url} data-sub-html={image.title}>
                    <img
                      alt=""
                      src={image.url}
                      data-src={image.url}
                      className="w-60 inline-block mr-3 rounded-2xl"
                      data-sub-html={
                        image.publish ? 'Published' : 'Not Published'
                      }
                    />
                  </a>
                </LightGallery>
                <div className="flex justify-end items-center gap-1 mt-.5">
                  {' '}
                  <span className="p-2 bg-gray-400/10 rounded">
                    {image.published ? (
                      <SolidStarIcon
                        onClick={(e) => {
                          e.preventDefault();
                          handleUnpublish(image.id);
                        }}
                        className="h-5 w-5 text-yellow-400"
                      />
                    ) : (
                      <StarIcon
                        onClick={(e) => {
                          e.preventDefault();
                          handlePublish(image.id);
                        }}
                        className="h-5 w-5 text-yellow-400"
                      />
                    )}
                  </span>
                  <span
                    className="p-1.5 bg-gray-400/10 mr-2 rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(image.id);
                    }}
                  >
                    <TrashIcon className="h-4 w-4 text-red-400  inline-block" />
                  </span>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MyCraftPage;
