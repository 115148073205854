import React from 'react';
import GoogleIcon from 'assets/icons/google.png';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from 'firebaseConfig';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        toast.success('Login successful');
        navigate('/home');
      })
      .catch((error) => {
        toast.error(error?.message || 'Something went wrong while logging in');
      });
  };

  return (
    <div className="bg-bgColor min-h-screen flex flex-col md:flex-row">
      <Toaster />

      <div className="bg-gradient-to-r from-indigo-500  to-pink-500 shadow md:w-96 w-full flex justify-center items-center py-5">
        <span className="text-4xl text-white font-bold text-center block ">
          VirArt
        </span>
      </div>
      <div className="flex justify-center items-center w-full min-h-screen ">
        <div>
          <div className="text-3xl font-bold text-white ">Login</div>
          <div className="text-gray-400 text-sm mt-2">
            Login to your account to continue
          </div>
          <button
            onClick={handleLogin}
            type="button"
            className="bg-gray-100 flex justify-between items-center text-black font-semibold py-3 px-16 rounded focus:outline-none focus:shadow-outlinex mt-4"
          >
            <img src={GoogleIcon} alt="google" className="w-5 h-5 mr-10" />
            <span>Login with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
